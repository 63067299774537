<template>
  <div :class="['activity-card__active', type]">
    <header>
      <div class="title">
        <div class="icon">
          <component :is="icon" />
        </div>
        <h3>{{ customLabel ? customLabel : title }}</h3>
      </div>
      <div v-if="isLoading" class="loading">
        <LoadingIcon />
      </div>
    </header>
    <main>
      <div class="timer-component">
        <span class="date">{{ date }}</span>
        <Timer :data="timer" />
      </div>
      <button v-if="showAddButton" class="add-button">
        <PlusIcon />
        <h3>{{ $t("ProductionProgress.Add") }}</h3>
      </button>
    </main>
  </div>
</template>

<script>
import SetupIcon from "@/assets/images/icons/setup-card-icon.svg";
import PauseIcon from "@/assets/images/icons/pause-card-icon.svg";
import PlayIcon from "@/assets/images/icons/play-card-icon.svg";
import FinishIcon from "@/assets/images/icons/finish-card-icon.svg";
import PlusIcon from "@/assets/images/icons/icon-plus.svg";

import LoadingIcon from "@/assets/images/pages/load-icon.svg";

import Timer from "./Timer.vue";

const icons = {
  setup: SetupIcon,
  stop: PauseIcon,
  start: PlayIcon,
  finish: FinishIcon,
};
export default {
  components: {
    SetupIcon,
    PauseIcon,
    PlayIcon,
    FinishIcon,
    Timer,
    PlusIcon,
    LoadingIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    customLabel: {
      type: String,
      default: null,
    },
    showAddButton: {
      type: Boolean,
      default: false,
    },
    timer: {
      type: Object,
      default: () => ({
        hour: null,
        minute: null,
        second: null,
      }),
    },
    date: {
      type: String,
      default: "10-11-2022 08:00",
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    icon() {
      return icons[this.type];
    },
  },
};
</script>

<style lang="scss">
$setup: #78909c;
$stop: #ef5350;
$start: #ffa726;
$finish: #66bb6a;

.activity-card__active {
  h3,
  .date {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 600;
  }

  flex: 1;
  border-radius: 24px;

  &.setup {
    background-color: $setup;
    path {
      fill: $setup;
    }
    button > h3 {
      color: $setup;
    }
  }
  &.stop {
    background-color: $stop;
    path {
      fill: $stop;
    }
    button > h3 {
      color: $stop;
    }
  }
  &.start {
    background-color: $start;
    path {
      fill: $start;
    }
    button > h3 {
      color: $start;
    }
  }
  &.finish {
    background-color: $finish;
    path {
      fill: $finish;
    }
    button > h3 {
      color: $finish;
    }
  }

  header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    padding: 16px;
    height: 64px;

    border-bottom: 1px solid #fff;

    .title {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 8px;

      .icon {
        background-color: #fff;
        height: 32px;
        width: 32px;
        padding: 8px;
        border-radius: 6px;

        path {
          transform: scale(0.2);
        }
      }

      h3 {
        font-size: 16px;
        line-height: 26px;

        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;

        color: #fff;
      }
    }

    .loading {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 290px;

    .date {
      color: #fff;
      font-size: 18px;
      line-height: 26px;
    }

    .timer-component {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      width: 100%;
      height: 100%;
    }

    button {
      border-radius: 0 0 24px 24px;
      h3 {
        margin: 0;
        font-size: 14px;
        line-height: 20px;
      }
      display: flex;
      justify-content: center;
      align-items: center;

      gap: 8px;
      height: 46px;
      width: 100%;

      outline: 0;
      border: 0;
    }
  }
}

.add-button {
  box-shadow: 0px 4px 24px rgba(58, 46, 38, 0.1);
  background: #fff;
}

@media (max-width: 480px) {
  .activity-card__active {
    .title {
      h3 {
        font-size: 14px;
        line-height: 20px;
        margin-top: 2px !important;
      }
      .icon {
        padding: 0;
        width: 30px;
        height: 30px;

        svg,
        path {
          transform: scale(0.95);
          margin-left: -2px;
          margin-top: -2px;
        }
      }
    }
    .loading {
      transform: scale(0.7);
    }
    main {
      height: 186px;

      .timer-component {
        gap: 18px;
        .date {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
  .add-button {
    padding: 4px !important;
    gap: 8px !important;
    height: 28px !important;
    h3 {
      font-size: 12px !important;
      line-height: 20px !important;
    }
    svg,
    path {
      transform: scale(0.926);
    }
  }
}
</style>
